<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" role="dialog">
      <div class="modal" ref="modal">
        <header class="modal-header">
          <slot name="header">
            <div class="modal-header-text">Gagal Mengumpulkan Tugas</div>
            <button type="button" class="btn-close btn-right" @click="close" aria-label="Close modal">
              <close-with-circle width="24" height="24" color="#f4ae56" />
            </button>
          </slot>
        </header>

        <section class="modal-body">
          <slot name="body">
            <div class="flex justify-center mb-8">
              <img :src="image" alt="redeem failed" class="image"/>
            </div>
            <p class="font-bold mb-3">{{ errorMessage }}</p>
            <p class="mb-3">Tugas gagal dikumpulkan. Silakan hubungi admin.</p>
            <div class="flex justify-center">
              <div class="upload-submit">
                <button @click="close">
                  <div class="font-bold">Kembali</div>
                </button>
              </div>
            </div>
          </slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import CloseWithCircle from '@/components/icons/CloseWithCircle'
import RedeemFailedImage from '@/assets/images/prakerja/redeem-failed.png'

export default {
  data() {
    return {
      image: RedeemFailedImage.toString()
    }
  },
  props: {
    close: Function,
    errorMessage: String
  },
  components: {
    CloseWithCircle
  },
  methods: {
  }
}

</script>

<style lang="scss" scoped>

.image {
  max-height: 200px;
}
.btn {
  padding: 8px 16px;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #ffffff;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  border-radius: 6px;
  width: 90%;
  max-width: 450px;
}

.modal-header,
.modal-footer {
  padding: 20px;
  display: flex;
}

.modal-header {
  justify-content: space-between;
  align-items: center;

  &-text {
    font-size: 18px;
    font-weight: bold;
  }
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 20px;

  &-drag {
    &-area {
      border: 2px dashed #ccc;
      border-radius: 6px;
      padding: 20px;
      display: flex;
      min-height: 200px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      transition: all 200ms linear;

      input {
        position: absolute;
        opacity: 0;
        overflow: hidden;
        height: 1px;
        width: 1px;
      }

      label {
        cursor: pointer;
        margin-top: 15px;

        span {
          background-color: #f4ae56;
          border: none;
          outline: none;
          color: #fff;
          border-radius: 3px;
          padding: 8px 16px;
          font-size: 12px;
          transition: all 100ms linear;
          display: inline-block;
          margin-bottom: 5px;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .upload-icon {
        &-text {
          margin-bottom: 5px;
        }
      }
    }
  }
}

.btn-close {
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: transparent;
  outline: none;
  padding: 0;
}

.btn {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.uploading {
  background-color: #eee;
}

.upload-submit {
  text-align: center;
  margin-top: 30px;

  button {
    background-color: #f4ae56;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 3px;
    padding: 8px 40px;
    transition: all 100ms linear;
    width: 100%;

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      background-color: #ccc;
      cursor: not-allowed;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.error {
  color: red;
  margin: 10px 0px;
  min-height: 20px;
}

.upload-files {
  &-name {
    display: block;
    color: #999;
  }

  &-size {
    font-size: 12px;
    color: #999;
    display: inline-block;
    margin-left: 5px;
  }
}

.notes {
  color: #999;
  font-size: 12px;
  margin: 5px 0px;
}</style>
